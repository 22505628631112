// import { CellOptions } from "@progress/kendo-react-excel-export/dist/npm/ooxml/CellOptionsInterface";

import { DropdownItemProps } from "semantic-ui-react";
import { PolicyType } from "../types";
import { policyTypesSecondInsured } from "features/case/add-case/AddCaseContext";

export const ampersandRegExp = /&amp;/gi;
export const defaultSiteTitle = `CaseTrack`;
export const entityRegExp = /(&.+;)/gi;
export const kendoPageSizes = { pageSizes: [10, 25, 50, 100] };
export const markupRegExp = /(<([^>]+)>)/gi;
export const quoteRegExp = /&quot;/gi;
export const spaceRegExp = /&nbsp;/gi;

export enum NoteStatus {
    "No Action Req" = 1,
    "Completed" = 2,
    "Open" = 3,
    "Overdue" = 4
}

export enum Role {
    "Administrator" = 1,
    "CaseManager" = 2,
    "Closing" = 16,
    "Fraud" = 17,
    "Legal" = 8,
    "Pricing" = 3,
    "Supervisor" = 18
}

export const policyTypeOptions: DropdownItemProps[] = Object.keys(PolicyType)
    .filter((value) => !isNaN(Number(value)))
    .map((key) => ({ key, value: +key, text: PolicyType[+key] }));

export const policyTypeOptionsSecondInsuredRequired: DropdownItemProps[] = Object.keys(PolicyType)
    .filter((value) => !isNaN(+value))
    .filter((value) => policyTypesSecondInsured.includes(+value))
    .map((key) => ({ key, value: +key, text: PolicyType[+key] }));

export const genderOptions = [
    { key: "M", value: "M", text: "Male" },
    { key: "F", value: "F", text: "Female" }
];

export const ratingCompanies = [
    { rating_type_id: 2, name: "S & P" },
    { rating_type_id: 4, name: "Moody's" },
    { rating_type_id: 3, name: "Fitch" },
    { rating_type_id: 1, name: "AM Best" },
    { rating_type_id: 5, name: "Weiss" }
];

// export const cellOptions: CellOptions = { verticalAlign: "center", textAlign: "center" };
// export const headerCellOptions: CellOptions = { textAlign: "center", bold: true };
// export const columnOptions = { cellOptions, headerCellOptions };
