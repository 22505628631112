import { CSSProperties, useEffect, useState } from "react";
import { Dropdown, DropdownItemProps, Form, Icon, Image, Label, Menu } from "semantic-ui-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AppContext } from "./AppContextProvider";
import { AuthContext } from "./AuthContextProvider";
import React from "react";
import { Role } from "services/Constants";
import logo from "../images/logo.png";
import { searchFromStart } from "services/Library";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import useDataService from "hooks/useDataService";

const style: CSSProperties = { marginTop: 0, borderRadius: 0, backgroundColor: "#636161" };

const caseDetailLinks: DropdownItemProps[] = [
    { key: 1, value: 1, text: "Summary" },
    { key: 2, value: 2, text: "Policy" },
    { key: 3, value: 3, text: "Insured" },
    { key: 4, value: 4, text: "Phases" },
    { key: 5, value: 5, text: "Pricing" },
    { key: 6, value: 6, text: "Pricing Model" },
    { key: 7, value: 7, text: "Buyer Review" },
    { key: 8, value: 8, text: "Doc Gen" },
    { key: 9, value: 9, text: "Underwriting" },
    { key: 10, value: 10, text: "Deficiency List" },
    { key: 11, value: 11, text: "Notes" }
];

const Navbar: React.FC = () => {
    const { case_user_id, userHasRole } = useContext(AuthContext);
    const { overdueNoteCount, setOverdueNoteCount } = useContext(AppContext);
    const [caseID, setCaseID] = useState<number>();
    const [caseDetailID, setCaseDetailID] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { validateCaseID, getNotes } = useDataService();
    const navigate = useNavigate();
    const { isAuthenticated, user, logout } = useAuth0();
    const location = useLocation();

    const link = `/case/${caseID}`;

    useEffect(() => {
        if (!case_user_id) return;
        const last = overdueNoteCount?.lastFetch;
        if (last && new Date().getTime() - last.getTime() < 120000) return;
        (async () => {
            try {
                const notes = await getNotes({
                    cases_id: null,
                    assigned_user_id: case_user_id,
                    isNoteCounter: true
                });
                const overdue = notes[0].OverdueNoteCount;
                setOverdueNoteCount({ noteCount: overdue ?? 0, lastFetch: new Date() });
            } catch (error) {
                console.error(error);
                toast.error("Error getting note count");
            }
        })();
    }, [case_user_id, getNotes, setOverdueNoteCount, location, overdueNoteCount]);

    const goToCaseDetail = async () => {
        if (!caseID) return;
        try {
            setIsLoading(true);
            let url = "";
            switch (caseDetailID) {
                case 1:
                    url = `${link}/summary`;
                    break;
                case 2:
                    url = `${link}/policy`;
                    break;
                case 3:
                    url = `${link}/insured`;
                    break;
                case 4:
                    url = `${link}/phases`;
                    break;
                case 5:
                    url = `${link}/pricing`;
                    break;
                case 6:
                    url = `${link}/pricing-model`;
                    break;
                case 7:
                    url = `${link}/buyer-review`;
                    break;
                case 8:
                    url = `${link}/document-generation`;
                    break;
                case 9:
                    url = `${link}/underwriting`;
                    break;
                case 10:
                    url = `${link}/deficiency-list`;
                    break;
                case 11:
                    url = `${link}/notes`;
                    break;
                default:
                    break;
            }
            const isValid = await validateCaseID(caseID);
            if (isValid) {
                navigate(url);
            } else {
                toast.error(`Case ${caseID} not found`);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Menu fluid inverted style={style} fixed="top">
            <Menu.Item as={Link} to="/search" header content={<Image src={logo} onClick={() => setCaseID(undefined)} />} />
            {isAuthenticated && (
                <>
                    {!userHasRole(Role.Fraud) && (
                        <>
                            <Dropdown item text="Search" style={{ color: "white" }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content="Brokers" as={Link} to="/broker" />
                                    <Dropdown.Item content="Buyers" as={Link} to="/buyer" />
                                    <Dropdown.Item content="Case" as={Link} to="/search" onClick={() => setCaseID(undefined)} />
                                    <Dropdown.Item content="Diseases" as={Link} to="/disease" />
                                    <Dropdown.Item content="Escrow Agents" as={Link} to="/escrow-agents" />
                                    <Dropdown.Item content="Fraud" as={Link} to="/fraud" />
                                    <Dropdown.Item content="Insurance Companies" as={Link} to="/carrier" />
                                    <Dropdown.Item content="Insureds" as={Link} to="/insured" />
                                    <Dropdown.Item content="Officers" as={Link} to="/officer" />
                                    <Dropdown.Item content="Owners" as={Link} to="/owner" />
                                    <Dropdown.Item content="Producers" as={Link} to="/producer" />
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown.Item
                                as={Link}
                                content="Reports"
                                icon="grid layout"
                                onClick={() => setCaseID(undefined)}
                                style={{ color: "white" }}
                                to="/report"
                            />
                            <Dropdown.Item
                                as={Link}
                                content="Add Case"
                                icon="plus circle"
                                onClick={() => setCaseID(undefined)}
                                style={{ color: "white" }}
                                to="/add-case"
                            />
                            <Dropdown.Item
                                as={Link}
                                content="To-Do"
                                icon="check square outline"
                                onClick={() => setCaseID(undefined)}
                                style={{ color: "white" }}
                                to="/to-do"
                            />
                            <Dropdown.Item
                                as={Link}
                                content="Legal Log"
                                icon="volleyball ball"
                                onClick={() => setCaseID(undefined)}
                                style={{ color: "white" }}
                                to="/legal-log"
                            />
                            <Dropdown item text="Data" style={{ color: "white" }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content="Rollback History" as={Link} to="/rollback-history" />
                                    <Dropdown.Item content="Document Template Management" as={Link} to="/document-template-management" />
                                    <Dropdown.Item content="Legal Matrix" as={Link} to="/legal-matrix" />
                                    <Dropdown.Item content="States" as={Link} to="/states" />
                                    {userHasRole(Role.Administrator) && <Dropdown.Item content="User Management" as={Link} to="/users" />}
                                </Dropdown.Menu>
                            </Dropdown>
                            {!!case_user_id && (
                                <Dropdown.Item as={Link} to="/notes" onClick={() => setCaseID(undefined)} style={{ color: "white" }}>
                                    <Icon name="mail" />
                                    My Notes
                                    <Label circular basic content={overdueNoteCount?.noteCount} />
                                </Dropdown.Item>
                            )}
                        </>
                    )}
                    <Form onSubmit={goToCaseDetail} size="mini" style={{ margin: "12px 0 0 12px" }}>
                        <Form.Group>
                            <Form.Input
                                onChange={(e, { value }) => setCaseID(+value)}
                                placeholder="Case ID"
                                required
                                style={{ width: "80px" }}
                                type="text"
                                value={caseID || ""}
                            />
                            <Form.Dropdown
                                className="width-80"
                                onChange={(e, { value }) => setCaseDetailID(value ? +value : 0)}
                                openOnFocus={false}
                                options={caseDetailLinks || []}
                                search={searchFromStart}
                                selection
                                selectOnBlur={true}
                                value={caseDetailID || ""}
                            />
                            <Form.Button
                                type="submit"
                                content="GO"
                                loading={isLoading}
                                color="green"
                                size="tiny"
                                style={{ marginLeft: "85px" }}
                            />
                        </Form.Group>
                    </Form>
                    <Dropdown item text={user?.email?.split("@")[0]} style={{ marginLeft: "auto", color: "white" }}>
                        <Dropdown.Menu>
                            <Dropdown.Item content="Logout" icon="log out" onClick={() => logout()} />
                        </Dropdown.Menu>
                    </Dropdown>
                </>
            )}
        </Menu>
    );
};

export default Navbar;
