import App from "./App";
import AppContextProvider from "components/AppContextProvider";
import Auth0ProviderWithHistory from "components/Auth0ProviderWithHistory";
import AuthContextProvider from "components/AuthContextProvider";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <AuthContextProvider>
                    <AppContextProvider>
                        <App />
                    </AppContextProvider>
                </AuthContextProvider>
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    </React.StrictMode>
);
