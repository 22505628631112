import { PolicyType, RatingType, SettlementType } from "../../../types";

import { CaseInsured } from "./AddCase";
import { CasePolicyInfo } from "../policy-info/types";
import { DropdownItemProps } from "semantic-ui-react";
import { createContext } from "react";

export interface AddCaseContextProps {
    brokerOptions?: DropdownItemProps[];
    carrierOptions?: DropdownItemProps[];
    insuredInfo1?: CaseInsured;
    insuredInfo2?: CaseInsured;
    policyInfo: Partial<CasePolicyInfo>;
    setInsuredInfo1: (data: CaseInsured | undefined) => void;
    setInsuredInfo2: (data: CaseInsured | undefined) => void;
    setPolicyInfo: (data: Partial<CasePolicyInfo>) => void;
    showErrors: boolean;
    setShowErrors: (data: boolean) => void;
}

// policy types where a second insured is required
export const policyTypesSecondInsured: number[] = [PolicyType.SDUL2, PolicyType.SDVUL2, PolicyType.SDWL2, PolicyType["Unknown 2"]];

export const defaultInsuredFormValues: CaseInsured = {
    person_id: 0,
    first_name: "",
    middle_initial: null,
    last_name: "",
    ssn: "",
    gender: "",
    birth_date: ""
};

export const defaultPolicyFormValues: Partial<CasePolicyInfo> = {
    broker_id: 0,
    gfa_actual: "",
    insurance_co_id: 0,
    issue_date: "",
    issue_date_unknown: false,
    policy_date: "",
    policy_date_unknown: false,
    policy_number: "",
    policy_type_id: 0,
    rating_co_1_id: RatingType["S & P"],
    rating_co_2_id: RatingType["S & P"],
    settlement_type_id: SettlementType.Life
};

const AddCaseContext = createContext<AddCaseContextProps>({
    brokerOptions: [] as DropdownItemProps[],
    carrierOptions: [] as DropdownItemProps[],
    insuredInfo1: defaultInsuredFormValues,
    insuredInfo2: defaultInsuredFormValues,
    policyInfo: defaultPolicyFormValues,
    showErrors: false,
    setInsuredInfo1: (): void => {
        throw new Error("Must be overridden in parent!");
    },
    setInsuredInfo2: (): void => {
        throw new Error("Must be overridden in parent!");
    },
    setPolicyInfo: (): void => {
        throw new Error("Must be overridden in parent!");
    },
    setShowErrors: (): void => {
        throw new Error("Must be overridden in parent!");
    }
});

export default AddCaseContext;
