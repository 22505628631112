import { Header, Icon } from "semantic-ui-react";
import React, { useContext } from "react";

import { AuthContext } from "./AuthContextProvider";
import { Navigate } from "react-router-dom";
import { Role } from "services/Constants";

const CatchAllRedirect: React.FC = () => {
    const { roles } = useContext(AuthContext);
    if (!roles) {
        return <Header color="grey" content="Checking permissions..." icon={<Icon name="cog" loading />} />;
    }
    return <Navigate to={roles.some((r) => r === Role.Fraud) ? "/contractor/fraud" : "/search"} replace />;
};

export default CatchAllRedirect;
