import { DropdownItemProps } from "semantic-ui-react";

export interface CaseSearchRow {
    allocated_buyer_name: string;
    allocated_buyer_id: string;
    brokers: string;
    cases_id: number;
    calc_le: number;
    carrier_rating: string;
    case_mgr: string;
    date_initiated: Date;
    decline_date: Date;
    decline_reason: string;
    gfa_actual: number;
    ins_dob: Date;
    ins_dod: Date;
    ins_fname: string;
    ins_lname: string;
    ins_pid: number;
    ins_p_created_date: Date;
    ins_ssn: string;
    insco_name: string;
    issue_date: Date;
    officer_ct: number;
    officer_name: string;
    owner_name: string;
    owner_type: string;
    partner_ct: number;
    phase_desc: string;
    policy_date: Date;
    policy_number: string;
    is_rdb: boolean;
    policy_type_desc: string;
    producer_ct: number;
    producer_id: string | number;
    producer_name: string;
    rating_agency: string;
    sec_dob: Date;
    sec_dod: Date;
    sec_ins_fname: string;
    sec_ins_lname: string;
    sec_pid: number;
    sec_p_created_date: Date;
    sec_ssn: string;
    servicing_id: string;
    settlement_type_desc: string;
    state_abb: string;
    status: "ACT" | "REJ" | "FUN";
}

export enum CaseStatus {
    Active = 1,
    Rejected = 2,
    Funded = 4
}

export type SearchParameters = {
    cases_id: number | null;
    allocated_buyer_id: number | null;
    calc_le_end: number | null;
    calc_le_start: number | null;
    case_mgr: string | null;
    dob_end: string | null;
    dob_start: string | null;
    face_end: number | null;
    face_start: number | null;
    first_name: string | null;
    initiated_end: string | null;
    initiated_start: string | null;
    ins_co_id: number | null;
    issue_date_end: string | null;
    issue_date_start: string | null;
    last_name: string | null;
    officer_id: number | null;
    partner_id: number | null;
    phase_end: Phase | null;
    phase_start: Phase | null;
    policy_date_end: string | null;
    policy_date_start: string | null;
    policy_number: string | null;
    policy_type_id: number | null;
    producer_id: number | null;
    sid: number | null;
    ssn: string | null;
    state_of_record_id: number | null;
    status_id: CaseStatus | null;
    unconverted_only: boolean;
};

export const caseStatusOptions: DropdownItemProps[] = [
    {
        key: "all",
        value: undefined,
        text: "All"
    },
    {
        key: "active",
        value: CaseStatus.Active,
        text: "Active"
    },
    {
        key: "funded",
        value: CaseStatus.Funded,
        text: "Funded"
    },
    {
        key: "rejected",
        value: CaseStatus.Rejected,
        text: "Rejected"
    }
];

export enum Phase {
    NewBusiness = 4000,
    Underwriting = 8000,
    Pricing = 12000,
    Commitment = 16000,
    CPGeneration = 26000,
    ExecutedReview = 31000,
    DeficiencyResolution = 34500,
    QCReview = 35000,
    PreAssignment = 35100,
    FunderRevAssgn = 35500,
    Funding = 35700,
    Rescission = 35900,
    PostRescission = 36000
}

export const phaseOptions: DropdownItemProps[] = [
    { text: "New Business", value: Phase.NewBusiness, key: Phase.NewBusiness },
    { text: "Underwriting", value: Phase.Underwriting, key: Phase.Underwriting },
    { text: "Pricing", value: Phase.Pricing, key: Phase.Pricing },
    { text: "Commitment", value: Phase.Commitment, key: Phase.Commitment },
    { text: "CP Generation", value: Phase.CPGeneration, key: Phase.CPGeneration },
    { text: "Executed Review", value: Phase.ExecutedReview, key: Phase.ExecutedReview },
    { text: "Deficiency Resolution", value: Phase.DeficiencyResolution, key: Phase.DeficiencyResolution },
    { text: "QC Review", value: Phase.QCReview, key: Phase.QCReview },
    { text: "Pre-Assignment", value: Phase.PreAssignment, key: Phase.PreAssignment },
    { text: "Funder Rev-Assgn", value: Phase.FunderRevAssgn, key: Phase.FunderRevAssgn },
    { text: "Funding", value: Phase.Funding, key: Phase.Funding },
    { text: "Rescission", value: Phase.Rescission, key: Phase.Rescission },
    { text: "Post-Rescission", value: Phase.PostRescission, key: Phase.PostRescission }
];
