import "react-toastify/dist/ReactToastify.css";

import { Grid } from "semantic-ui-react";
import IdleTimeout from "@itm21st/idle-timeout-react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

const Layout: React.FC = () => {
    const { logout } = useAuth0();
    return (
        <>
            <Navbar />
            <div style={{ paddingTop: "68px", backgroundColor: "white", position: "sticky", width: "100%", zIndex: 4, top: 0 }} />
            <Grid padded="horizontally">
                <Grid.Row centered>
                    <Grid.Column width={16}>
                        <Outlet />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <ToastContainer theme="colored" />
            <IdleTimeout onIdle={() => logout()} />
        </>
    );
};

export default Layout;
