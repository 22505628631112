import React, { createContext, useCallback, useEffect, useState } from "react";

import { Role } from "services/Constants";
import { notImplemented } from "services/Library";
import { useAuth0 } from "@auth0/auth0-react";
import useDatabroker from "hooks/useDatabroker";

interface AuthContextProps {
    userHasRole: (role: Role) => boolean;
    userHasAnyRole: (roles: Role[]) => boolean;
    case_user_id?: number | undefined;
    roles?: Role[] | undefined;
    case_user_name?: string;
    case_user_email?: string;
}

interface Case_User {
    case_user_id: number;
    auth_id: string;
    u_name: string;
    role_level: number;
}

export const AuthContext = createContext<AuthContextProps>({
    userHasRole: notImplemented,
    userHasAnyRole: notImplemented
});

interface AuthContextProviderProps {
    children: React.ReactNode;
}

const AuthContextProvider: React.FC<AuthContextProviderProps> = (props: AuthContextProviderProps) => {
    const { children } = props;
    const [case_user_id, set_case_user_id] = useState<number | undefined>();
    const [case_user_name, set_case_user_name] = useState<string | undefined>();
    const [case_user_email, set_case_user_email] = useState<string | undefined>();
    const [roles, setRoles] = useState<Role[] | undefined>();
    const databroker = useDatabroker();
    const { isAuthenticated, user } = useAuth0();

    const getCaseUserID = useCallback(
        async (auth_id: string): Promise<number | undefined> => {
            const { error, data } = await databroker.search<Case_User>({
                objectName: "Casetrack.dbo.Case_User",
                select: ["case_user_id"],
                where: { auth_id }
            });
            if (error) {
                throw new Error(`Error getting case_user_id: ${error}`);
            }
            if (data && data.length) {
                return data[0].case_user_id;
            }
            window.alert(`No case_user_id found for logged in user. The application will not function as expected`);
            return undefined;
        },
        [databroker]
    );

    const getUserRoles = useCallback(
        async (auth_id: string): Promise<Role[]> => {
            const { error, data } = await databroker.query<{ role_level: Role }>({
                from: { objectName: "Casetrack.dbo.Case_User_Role", as: "r" },
                join: [
                    {
                        from: { objectName: "Casetrack.dbo.Case_User", as: "u" },
                        type: "inner",
                        on: ["u.case_user_id", "r.case_user_id"]
                    }
                ],
                select: ["r.role_level"],
                where: { "u.auth_id": auth_id }
            });
            if (error || !data) {
                throw new Error(`Error getting user roles: ${error}`);
            }
            return data.map((d) => d.role_level);
        },
        [databroker]
    );

    useEffect(() => {
        if (!isAuthenticated || !user) return;
        const { email, nickname, sub: auth_id } = user;
        (async () => {
            const [caseUserID, userRoles] = await Promise.all([getCaseUserID(auth_id as string), getUserRoles(auth_id as string)]);
            set_case_user_id(caseUserID);
            set_case_user_email(email);
            set_case_user_name(nickname);
            setRoles(userRoles);
        })();
    }, [getCaseUserID, getUserRoles, isAuthenticated, user]);

    const userHasRole = useCallback(
        (role: Role) => {
            return !!roles?.includes(role);
        },
        [roles]
    );

    const userHasAnyRole = useCallback(
        (roleList: Role[]) => {
            return roleList.some((rl) => roles?.includes(rl));
        },
        [roles]
    );

    return (
        <AuthContext.Provider value={{ userHasRole, userHasAnyRole, case_user_email, case_user_id, case_user_name, roles }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
