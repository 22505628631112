import { Dimmer, Loader } from "semantic-ui-react";

import React from "react";

interface ILoaderUI {
    loaderProps?: { [key: string]: any };
    showLoader?: boolean;
}

const LoaderUI: React.FC<ILoaderUI> = ({ showLoader }) => (
    <Dimmer active={showLoader} inverted inline="centered">
        <Loader size="big" />
    </Dimmer>
);

export default LoaderUI;
