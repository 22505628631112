import { CarrierProduct, EscrowAgent, InsuranceCompany, Rating } from "features/case/policy-info/types";
import { CaseInfo, CaseUser, CompanyPerson, CompanyStatus, GetNotesParams, Note, RegionRep, State } from "../types";
import { EscrowAgentAddress, OrderManagementData } from "features/buyer/types";
import { NoteStatus, Role, ratingCompanies } from "services/Constants";
import { parseDates, removeNonNumerics } from "services/Library";

import { BuyerReviewDeclineReason } from "features/case/buyer-review/types";
import { DropdownItemProps } from "semantic-ui-react";
import { LegalLogDetail } from "features/legal-log/types";
import { Producer } from "features/producer/types";
import { StrictWhere } from "@itm21st/databroker";
import { toast } from "react-toastify";
import { useCallback } from "react";
import useDatabroker from "hooks/useDatabroker";

interface UseDataServiceOutput {
    getBuyerReviewDeclineReasons: () => Promise<DropdownItemProps[]>;
    getCarrierProducts: () => Promise<CarrierProduct[]>;
    getCaseInfo: (cases_id: number) => Promise<CaseInfo>;
    getCaseUsers: (forNotes?: boolean) => Promise<CaseUser[]>;
    getCaseUsersByRole: (role: Role) => Promise<CaseUser[]>;
    getCompanyOfficers: (company_id: number) => Promise<CompanyPerson[]>;
    getCompanyStatuses: () => Promise<CompanyStatus[]>;
    getEscrowAgentAddresses: (buyer_id: number) => Promise<EscrowAgentAddress[]>;
    getEscrowAgents: () => Promise<EscrowAgent[]>;
    getExistingSSN: (ssn: string) => Promise<boolean>;
    getExistingTaxIDNumber: (taxIDNumber: string) => Promise<boolean>;
    getInsuranceCompanies: () => Promise<InsuranceCompany[]>;
    getNotes: (noteInfo: GetNotesParams) => Promise<Note[]>;
    getOrderManagement: (buyer_id: number) => Promise<OrderManagementData[]>;
    getProducers: (broker_id: number) => Promise<Producer[]>;
    getRatingCompanies: () => Rating[];
    getRegionReps: () => Promise<RegionRep[]>;
    getRoleIdsForUsers: (case_user_id: number) => Promise<{ role_level: number }[]>;
    getStates: () => Promise<State[]>;
    validateCaseID: (cases_id: number) => Promise<boolean>;
}

const useDataService = (): UseDataServiceOutput => {
    const databroker = useDatabroker();

    const getCaseUsers = useCallback(
        async (forNotes?: boolean): Promise<CaseUser[]> => {
            const whereClause: StrictWhere<CaseUser> = {
                inactive: false
            };
            if (forNotes) {
                whereClause.turn_off_notes = false;
            }
            const { data, error } = await databroker.search<CaseUser>({
                objectName: "Casetrack.dbo.Case_User",
                select: ["fname", "lname", "u_name", "case_user_id", "email", "department_id"],
                where: whereClause,
                orderBy: [["fname", "ASC"]]
            });
            if (error || !data) {
                throw new Error(error || `Error getting case users`);
            }
            return data;
        },
        [databroker]
    );

    const getBuyerReviewDeclineReasons = useCallback(async () => {
        const { data, error } = await databroker.query<BuyerReviewDeclineReason>({
            select: ["decline_reason_id", "name"],
            from: { objectName: "Casetrack.dbo.Buyer_Review_Decline_Type" },
            where: { inactive: 0 },
            orderBy: [["name", "ASC"]]
        });
        if (error || !data) {
            console.error(error);
            throw new Error("Error getting buyer review decline reasons");
        }
        return data.map((item) => ({
            key: item.decline_reason_id,
            value: item.decline_reason_id,
            text: item.name
        }));
    }, [databroker]);

    const getCarrierProducts = useCallback(async () => {
        const { data, error } = await databroker.query<CarrierProduct>({
            select: ["ins_co_prod_id", "prod_name", "insurance_co_id"],
            from: {
                objectName: "Casetrack.dbo.Ins_Co_Product"
            },
            orderBy: [["ins_co_prod_id", "asc"]]
        });
        if (error || !data) {
            throw new Error(error || "No data received from Databroker");
        }
        return data;
    }, [databroker]);

    const getCaseInfo = useCallback(
        async (cases_id: number): Promise<CaseInfo> => {
            const { data, error } = await databroker.sproc<CaseInfo>({
                objectName: "Casetrack.dbo.GetCaseInfo",
                parameters: { cases_id }
            });
            if (error || !data) {
                // check for duplicate pricing rows if this error occurs
                toast.error(`Error getting case info`);
                throw new Error(error || `Error getting case info`);
            }
            const tempData = parseDates(data, ["decline_date", "rescission_end_date"], "TRIM Z");
            return tempData[0];
        },
        [databroker]
    );

    const getCaseUsersByRole = useCallback(
        async (role: Role) => {
            const { data, error } = await databroker.query<CaseUser>({
                select: ["cu.fname", "cu.lname", "cu.u_name", "cu.case_user_id", "cu.email"],
                from: {
                    objectName: "Casetrack.dbo.Case_User",
                    as: "cu"
                },
                join: [
                    {
                        from: { objectName: "Casetrack.dbo.Case_User_Role", as: "cur" },
                        type: "inner",
                        on: ["cu.case_user_id", "cur.case_user_id"]
                    }
                ],
                where: { "cur.role_level": role, "cu.inactive": false },
                orderBy: [["cu.fname", "ASC"]]
            });
            if (error || !data) {
                throw new Error(error || "No data received from databroker");
            }
            return data;
        },
        [databroker]
    );

    const getCompanyOfficers = useCallback(
        async (company_id: number) => {
            const { data, error } = await databroker.sproc<CompanyPerson>({
                objectName: "Casetrack.dbo.GetCompanyOfficers",
                parameters: { company_id }
            });
            if (error || !data) {
                throw new Error(error || "no data");
            }
            const tempData = parseDates(data, ["birth_date"], "TRIM Z");
            return tempData;
        },
        [databroker]
    );

    const getCompanyStatuses = useCallback(async () => {
        const { data, error } = await databroker.query<CompanyStatus>({
            select: ["broker_status_id", "broker_status_desc"],
            from: { objectName: "Casetrack.dbo.Broker_Status" },
            orderBy: [["broker_status_desc", "ASC"]]
        });
        if (error || !data) {
            throw new Error(error);
        }
        return data;
    }, [databroker]);

    const getEscrowAgents = useCallback(async () => {
        const { data, error } = await databroker.query<EscrowAgent>({
            select: ["ea_index.index_id", "ea_index.buyer_id", "ea.escrow_agent_id", "c.company_name"],
            from: {
                objectName: "Casetrack.dbo.Buyer_EA_Address_Index",
                as: "ea_index"
            },
            join: [
                {
                    from: { objectName: "Casetrack.dbo.Escrow_Agent", as: "ea" },
                    on: ["ea.escrow_agent_id", "ea_index.escrow_agent_id"]
                },
                {
                    from: { objectName: "Casetrack.dbo.Company", as: "c" },
                    on: ["c.company_id", "ea.company_id"]
                }
            ]
        });
        if (error || !data) {
            throw new Error(error);
        }
        return data;
    }, [databroker]);

    const getEscrowAgentAddresses = useCallback(
        async (buyer_id: number) => {
            const { data, error } = await databroker.sproc<EscrowAgentAddress>({
                objectName: "Casetrack.dbo.GetBuyerEscrowAgentAddressList",
                parameters: { buyer_id: buyer_id }
            });
            if (error || !data) {
                console.error(error);
                throw new Error("Error getting escrow agent addresses");
            }
            return data;
        },
        [databroker]
    );

    const getExistingSSN = useCallback(
        async (ssn: string): Promise<boolean> => {
            const { data, error } = await databroker.sproc({
                objectName: "Casetrack.dbo.GetSSNTaxIDNumberSearch",
                parameters: { SSN: ssn, TIN: null }
            });
            if (error || !data) {
                throw new Error(error ?? "no data returned");
            }
            //dbo.Person has ssn values of '0' and ''. These values represent a person with an unknown ssn
            //return true = incoming ssn already exists
            //return false = incoming ssn is new
            if (!!data.length && (data[0] === "0" || data[0] === "")) {
                return false;
            } else {
                return !!data.length;
            }
        },
        [databroker]
    );

    const getExistingTaxIDNumber = useCallback(
        async (taxIDNumber: string) => {
            const TINWithoutHyphens = removeNonNumerics(taxIDNumber);
            const { data, error } = await databroker.sproc({
                objectName: "Casetrack.dbo.GetSSNTaxIDNumberSearch",
                parameters: { SSN: null, TIN: TINWithoutHyphens }
            });
            if (error || !data) {
                throw new Error(error || "no data returned");
            }
            return !!data.length;
        },
        [databroker]
    );

    const getInsuranceCompanies = useCallback(async () => {
        const { data, error } = await databroker.query<InsuranceCompany>({
            select: ["i_co.insurance_co_id", "c.company_name"],
            from: {
                objectName: "Casetrack.dbo.Insurance_Co",
                as: "i_co"
            },
            join: [
                {
                    from: { objectName: "Casetrack.dbo.Company", as: "c" },
                    on: ["i_co.company_id", "c.company_id"]
                }
            ],
            orderBy: [["c.company_name", "asc"]]
        });
        if (error || !data) {
            throw new Error(error || "No data received from Databroker");
        }
        return data;
    }, [databroker]);

    const getNotes = useCallback(
        async (params: GetNotesParams) => {
            const { data, error } = await databroker.sproc<Note>({
                objectName: "Casetrack.dbo.GetNotes",
                parameters: {
                    cases_id: params.cases_id,
                    assigned_user_id: params.assigned_user_id,
                    isNoteCounter: params.isNoteCounter
                }
            });
            if (error || !data) {
                throw new Error(error);
            }
            const tempData = parseDates(
                data,
                ["created_date", "followup_date", "followup_complete_date", "assigned_user_read_date", "last_updated_date"],
                "TRIM Z"
            ).map((item) => {
                let legalLogDetails: LegalLogDetail[] = [];
                if (item.legal_log_details) {
                    legalLogDetails = JSON.parse(item.legal_log_details as string).map((detail: LegalLogDetail) => ({
                        ...detail,
                        note_date: new Date(detail.note_date)
                    }));
                }

                //the text for the initial request does not need to be displayed in Followup column of the NotesGrid, so that record can be removed from legalLogDetails.
                legalLogDetails.pop();

                const { action_required, followup_complete, followup_date } = item;
                let note_status_id = 0;
                if (!action_required) {
                    note_status_id = 1;
                } else if (followup_complete) {
                    note_status_id = 2;
                } else if (new Date() <= followup_date) {
                    note_status_id = 3;
                } else if (followup_date && new Date() > followup_date) {
                    note_status_id = 4;
                }

                return {
                    ...item,
                    note_status_id,
                    note_status: NoteStatus[note_status_id] || "Unknown",
                    legal_log_details: legalLogDetails
                };
            });
            return tempData;
        },
        [databroker]
    );

    const getOrderManagement = useCallback(
        async (buyer_id: number) => {
            const { data, error } = await databroker.sproc<OrderManagementData>({
                objectName: "Casetrack.dbo.GetBuyerOrderManagement",
                parameters: { buyer_id: buyer_id }
            });
            if (error || !data) {
                console.error(error);
                throw new Error("Error getting order management");
            }
            return data;
        },
        [databroker]
    );

    const getProducers = useCallback(
        async (broker_id: number) => {
            const { data, error } = await databroker.query<Producer>({
                select: [
                    "producer_id",
                    "producer_name",
                    "partner_id",
                    "partner_name",
                    "company_id",
                    "last_review_date",
                    "next_review_date"
                ],
                from: { objectName: "Casetrack.dbo.Producer" },
                where: { partner_id: broker_id }
                // orderBy: [["first_name", "asc"]]
            });
            if (error || !data) {
                throw new Error(error || "No data received from databroker");
            }
            return data;
        },
        [databroker]
    );

    const getRatingCompanies = useCallback(() => ratingCompanies, []);

    const getRegionReps = useCallback(async () => {
        const { data, error } = await databroker.query<RegionRep>({
            select: ["region_name", "region_id"],
            from: { objectName: "Casetrack.dbo.Region" },
            orderBy: [["region_name", "ASC"]]
        });
        if (error || !data) {
            throw new Error(error);
        }
        return data;
    }, [databroker]);

    const getRoleIdsForUsers = useCallback(
        async (case_user_id: number) => {
            const { data, error } = await databroker.query<{ role_level: number }>({
                select: ["role_level"],
                from: { objectName: "Casetrack.dbo.Case_User_Role" },
                where: { case_user_id: case_user_id }
            });
            if (error || !data) {
                throw new Error(error);
            }
            return data;
        },
        [databroker]
    );

    const getStates = useCallback(async () => {
        const { data, error } = await databroker.sproc<State>({
            objectName: "Casetrack.dbo.GetStateList",
            parameters: {}
        });
        if (error || !data) {
            throw new Error(error || "Error getting states");
        }
        const tempData = parseDates(data, ["expiration_date"], "TRIM Z");
        return tempData;
    }, [databroker]);

    const validateCaseID = useCallback(
        async (cases_id: number): Promise<boolean> => {
            const { error, data } = await databroker.search({
                objectName: "Casetrack.dbo.Cases",
                select: ["cases_id"],
                where: { cases_id }
            });
            if (error || !data) {
                throw new Error(`Error searching for case ${cases_id}: ${error}`);
            }
            return data.length ? true : false;
        },
        [databroker]
    );

    return {
        getBuyerReviewDeclineReasons,
        getCarrierProducts,
        getCaseInfo,
        getCaseUsers,
        getCaseUsersByRole,
        getCompanyOfficers,
        getCompanyStatuses,
        getEscrowAgentAddresses,
        getEscrowAgents,
        getExistingSSN,
        getExistingTaxIDNumber,
        getInsuranceCompanies,
        getNotes,
        getOrderManagement,
        getProducers,
        getRatingCompanies,
        getRegionReps,
        getRoleIdsForUsers,
        getStates,
        validateCaseID
    };
};

export default useDataService;
