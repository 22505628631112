import { CompanyLike } from "types";
import { DropdownItemProps } from "semantic-ui-react";
import { isDate } from "lodash";

export const kendoDateFormat = "{0: MM/dd/yyyy}";
export const kendoDateTimeFormat = "{0: MM/dd/yyyy, h:mm a}";

export const emdash = `—`;

/**
 * Returns an array of objects by parsing the selected fields as Date objects
 * @param {Object[]} data - An array of objects
 * @param {string[]} dateFields - An array of field names to be parsed as Date objects
 */

export const parseDates = <T>(data: T[], fields: (keyof T)[], behavior?: "TRIM Z"): T[] => {
    const parsed = data.map((row) => {
        const mutableRow = { ...row };
        fields.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = mutableRow[field] as any;
            if (value) {
                const preparedValue = behavior === "TRIM Z" ? value.slice(0, -1) : value;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mutableRow[field] = new Date(preparedValue) as any;
            }
        });
        return mutableRow;
    });

    return parsed as T[];
};

export function toIsoDateString(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

export function fromIsoDateString(date: string) {
    const [year, month, day] = date.split("-");
    return new Date(+year, +month - 1, +day);
}

export const getIsoFormDate = (date: string | Date | null): string => {
    if (!date) {
        return "";
    }
    if (isDate(date)) {
        date = date.toISOString();
    }
    return date.split("T")[0];
};

export function displayDate(date?: Date | string | undefined | null) {
    if (isDate(date)) {
        return date.toLocaleDateString();
    } else if (typeof date === `string`) {
        return `INVALID DATE`;
    }
    return null;
}

export const formatCurrency = (value: number, decimals = 2): string => {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: decimals }).format(value);
};

export const addMonthsToDate = (date: Date, n: number): Date => {
    return new Date(date.getFullYear(), date.getMonth() + n, date.getDate());
};

export const getFirstOfMonth = (date?: Date): Date => {
    const refDate = date ?? new Date();
    return new Date(refDate.getFullYear(), refDate.getMonth(), 1);
};

export const getLastOfMonth = (date?: Date): Date => {
    const refDate = date ?? new Date();
    const firstOfNextMonth = addMonthsToDate(getFirstOfMonth(refDate), 1);
    return addDaysToDate(firstOfNextMonth, -1);
};

export const addDaysToDate = (date: Date, n: number): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + n);
};

export const simpleFormatDateTime = (date: Date | undefined): string => {
    if (!date) {
        return "";
    }
    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
};

export const formatSSN = (ssn: string): string => {
    if (!ssn || ssn.length !== 9) {
        return ssn;
    }
    return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5, 9)}`;
};

export const removeNonNumerics = (input: string) => {
    if (!input) {
        return input;
    }
    return input.replace(/[^0-9]+/g, "");
};

export const searchFromStart = (options: DropdownItemProps[], query: string) => {
    return options.filter((opt) => {
        const { text } = opt;
        if (text && typeof text !== "string") {
            throw new Error("Must search text");
        } else {
            return (text as string).toUpperCase().startsWith(query.toUpperCase());
        }
    });
};

export const roundWithPrecision = (number: number, precision: number) => {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
};

export const getCompanyDropdown = (items: CompanyLike[]) => items.map((i) => ({ key: i.id, value: i.id, text: i.name }));

export const trimAll = (obj: any) => {
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "string") {
            obj[key] = obj[key].trim();
        }
    });
    return obj;
};

export const notImplemented = () => {
    throw new Error(`Not implemented!`);
};
