import { CaseSearchRow, CaseStatus, Phase, SearchParameters } from "features/case-search/types";
import { Company, OverdueNoteCount, State } from "types";
import { createContext, useEffect, useState } from "react";

import { Broker } from "features/broker/types";
import { DropdownItemProps } from "semantic-ui-react";
import React from "react";
import { Role } from "services/Constants";
import { notImplemented } from "services/Library";
import useDataService from "hooks/useDataService";

interface AppContextProviderProps {
    children: React.ReactNode;
}

export const defaultSearchParameters: SearchParameters = {
    allocated_buyer_id: null,
    calc_le_end: null,
    calc_le_start: null,
    case_mgr: null,
    cases_id: null,
    dob_end: null,
    dob_start: null,
    face_end: null,
    face_start: null,
    first_name: null,
    initiated_end: null,
    initiated_start: null,
    ins_co_id: null,
    issue_date_end: null,
    issue_date_start: null,
    last_name: null,
    officer_id: null,
    partner_id: null,
    phase_end: Phase.PostRescission,
    phase_start: Phase.NewBusiness,
    policy_date_end: null,
    policy_date_start: null,
    policy_number: null,
    policy_type_id: null,
    producer_id: null,
    sid: null,
    ssn: null,
    state_of_record_id: null,
    status_id: CaseStatus.Active,
    unconverted_only: false
};

interface AppContextProps {
    addressUpdate: boolean;
    banks: Company[] | undefined;
    brokers: Broker[] | undefined;
    buyerOptions: DropdownItemProps[] | undefined;
    caseManagerOptions: DropdownItemProps[] | undefined;
    closingAnalystOptions: DropdownItemProps[] | undefined;
    gridData: CaseSearchRow[] | undefined;
    includeAllNotes: boolean;
    insuranceCompanies?: DropdownItemProps[];
    loading: boolean;
    officers: DropdownItemProps[] | undefined;
    overdueNoteCount: OverdueNoteCount | undefined;
    producers: DropdownItemProps[] | undefined;
    ratingOptions: DropdownItemProps[] | undefined;
    searchParameters: SearchParameters;
    setAddressUpdate: (data: boolean) => void;
    setBanks: (banks: Company[]) => void;
    setBrokers: (companies: Broker[]) => void;
    setBuyerOptions: (companies: DropdownItemProps[]) => void;
    setGridData: (data: CaseSearchRow[]) => void;
    setIncludeAllNotes: (data: boolean) => void;
    setInsuranceCompanies: (companies: DropdownItemProps[]) => void;
    setLoading: (data: boolean) => void;
    setOfficers: (companies: DropdownItemProps[]) => void;
    setOverdueNoteCount: (data: OverdueNoteCount) => void;
    setProducers: (producers: DropdownItemProps[]) => void;
    setSearchParameters: React.Dispatch<React.SetStateAction<SearchParameters>>;
    setStateOptions: (data: DropdownItemProps[] | undefined) => void;
    setStates: (data: State[]) => void;
    stateOptions: DropdownItemProps[] | undefined;
    states: State[] | undefined;
}

export const AppContext = createContext<AppContextProps>({
    addressUpdate: false,
    banks: [],
    brokers: [],
    buyerOptions: [],
    caseManagerOptions: [],
    closingAnalystOptions: [],
    gridData: {} as CaseSearchRow[],
    includeAllNotes: false,
    loading: false,
    officers: [],
    overdueNoteCount: {} as OverdueNoteCount,
    producers: [],
    ratingOptions: [],
    searchParameters: defaultSearchParameters,
    setAddressUpdate: notImplemented,
    setBanks: notImplemented,
    setBrokers: notImplemented,
    setBuyerOptions: notImplemented,
    setGridData: notImplemented,
    setIncludeAllNotes: notImplemented,
    setInsuranceCompanies: notImplemented,
    setLoading: notImplemented,
    setOfficers: notImplemented,
    setOverdueNoteCount: notImplemented,
    setProducers: notImplemented,
    setSearchParameters: notImplemented,
    setStateOptions: notImplemented,
    setStates: notImplemented,
    stateOptions: [],
    states: []
});

const AppContextProvider: React.FC<AppContextProviderProps> = (props: AppContextProviderProps) => {
    const { children } = props;
    const { getStates, getRatingCompanies, getCaseUsersByRole } = useDataService();
    const [addressUpdate, setAddressUpdate] = useState<boolean>(false);
    const [banks, setBanks] = useState<Company[]>();
    const [brokers, setBrokers] = useState<Broker[]>();
    const [buyerOptions, setBuyerOptions] = useState<DropdownItemProps[]>();
    const [caseManagerOptions, setCaseManagerOptions] = useState<DropdownItemProps[]>();
    const [closingAnalystOptions, setClosingAnalystOptions] = useState<DropdownItemProps[]>();
    const [gridData, setGridData] = useState<CaseSearchRow[]>();
    const [includeAllNotes, setIncludeAllNotes] = useState<boolean>(false);
    const [insuranceCompanies, setInsuranceCompanies] = useState<DropdownItemProps[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [officers, setOfficers] = useState<DropdownItemProps[]>();
    const [overdueNoteCount, setOverdueNoteCount] = useState<OverdueNoteCount>();
    const [producers, setProducers] = useState<DropdownItemProps[]>();
    const [ratingOptions, setRatingOptions] = useState<DropdownItemProps[]>();
    const [searchParameters, setSearchParameters] = useState<SearchParameters>({ ...defaultSearchParameters });
    const [stateOptions, setStateOptions] = useState<DropdownItemProps[] | undefined>();
    const [states, setStates] = useState<State[]>();

    useEffect(() => {
        if (states) return;
        (async () => {
            const tempStates = await getStates();
            setStates(tempStates);
            setStateOptions(
                tempStates.map((item) => ({
                    value: item.state_id,
                    key: item.state_id,
                    text: `${item.state_name}`
                }))
            );
        })();
    }, [getStates, setStateOptions, setStates, states]);

    useEffect(() => {
        (async () => {
            const data = getRatingCompanies();
            setRatingOptions(
                data.map((item) => ({
                    value: item.rating_type_id,
                    key: item.rating_type_id,
                    text: item.name
                }))
            );
        })();
    }, [getRatingCompanies]);

    useEffect(() => {
        (async () => {
            const data = await getCaseUsersByRole(Role.Closing);
            setClosingAnalystOptions(
                data.map((item) => ({
                    key: item.case_user_id,
                    value: item.case_user_id,
                    text: item.u_name
                }))
            );
        })();
    }, [getCaseUsersByRole]);

    useEffect(() => {
        (async () => {
            const caseManagers = await getCaseUsersByRole(Role.CaseManager);
            setCaseManagerOptions(
                caseManagers.map((manager) => ({
                    key: manager.case_user_id,
                    value: manager.case_user_id,
                    text: `${manager.fname} ${manager.lname}`
                }))
            );
        })();
    }, [getCaseUsersByRole]);

    return (
        <AppContext.Provider
            value={{
                addressUpdate,
                banks,
                brokers,
                buyerOptions,
                caseManagerOptions,
                closingAnalystOptions,
                gridData,
                includeAllNotes,
                insuranceCompanies,
                loading,
                officers,
                overdueNoteCount,
                producers,
                ratingOptions,
                searchParameters,
                setAddressUpdate,
                setBanks,
                setBrokers,
                setBuyerOptions,
                setGridData,
                setIncludeAllNotes,
                setInsuranceCompanies,
                setLoading,
                setOfficers,
                setOverdueNoteCount,
                setProducers,
                setSearchParameters,
                setStateOptions,
                setStates,
                stateOptions,
                states
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
export default AppContextProvider;
