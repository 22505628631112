import { LegalLogDetail } from "features/legal-log/types";
import { Role } from "services/Constants";

export type Common<A, B> = {
    [P in keyof A & keyof B]: A[P] | B[P];
};

export type ParamOf<T> = keyof T;

export type ValidationResult =
    | {
          isValid: boolean;
          errorMessage?: string;
      }
    | Promise<{
          isValid: boolean;
          errorMessage?: string;
      }>;

export type Validator = (value: any, editMode?: boolean) => ValidationResult;
export type Formatter = (value: string) => string;

export type IdEntity = {
    id: number | null;
};

export interface Company {
    company_id: number;
    company_name: string;
    company_type_id: number;
    web: string;
}

export enum CompanyType {
    "Insurance Company" = 1,
    "Partner" = 2,
    "Producer" = 3,
    "Internal Underwriter" = 4,
    "Escrow Agent" = 5,
    "Broker Dealer" = 6,
    "Third Party Underwriter" = 7,
    "Life Settlement Provider" = 8,
    "Bank" = 9,
    "Supplier" = 10
}

export interface CompanyLike {
    id: number;
    name: string;
    company_id: number;
}

export interface CompanyStatus {
    broker_status_id: number;
    broker_status_desc: string;
}

export interface CaseUser {
    case_user_id: number;
    fname: string;
    lname: string;
    u_name: string;
    inactive: boolean;
    department_id: number;
    email: string;
    auth_id: string;
    turn_off_notes?: boolean;
}

export enum SettlementType {
    Life = 1,
    Viatical = 2
}

export enum RatingType {
    "AM Best" = 1,
    "S & P" = 2,
    "Fitch" = 3,
    "Moody's" = 4,
    "Weiss" = 5
}

export enum PolicyType {
    "First to Die" = 1,
    "Group" = 2,
    "Ordinary Life" = 3,
    "Term" = 4,
    "SDUL1" = 5,
    "SDUL2" = 6,
    "SDVUL1" = 7,
    "SDVUL2" = 8,
    "SDWL1" = 9,
    "SDWL2" = 10,
    "UL" = 11,
    "VUL" = 12,
    "Unknown 1" = 13,
    "Unknown 2" = 14,
    "Convertible Term" = 15,
    "Non-Convertible Term" = 16
}

export interface CaseInfo {
    allocated_buyer: string | null;
    bid_deadline?: string;
    broker_name: string;
    case_event: string;
    case_is_locked: boolean;
    case_phase: string;
    case_status_id: number;
    case_status: string;
    cases_id: number;
    converted_by: string;
    converted_date: string;
    created_by: string;
    date_initiated: string;
    decline_lock_master_id: number;
    decline_date?: Date;
    decline_type: string;
    dom_state: string;
    insured_name: string;
    is_multiple_insured_policy_type?: boolean;
    has_second_insured?: boolean;
    last_event_master_id: number;
    last_phase_master_id: number;
    rescission_end_date: Date | null;
}

export interface CaseBrokerData {
    cases_id: number;
    partner_index_id: number;
    partner_id: number;
    partner_name: string;
    producer_index_id: number;
    producer_id: number;
    case_partner_index_id: number;
    producer_name: string;
    officer_index_id: number;
    officer_id: number;
    case_producer_index_id: number;
    officer_name: string;
}

export interface CompanyPerson {
    company_person_id: number;
    company_person_name: string;
    first_name: string;
    middle_initial: string;
    last_name: string;
    company_id: number;
    company_name: string;
    email: string;
    birth_date: string | null;
    receive_email: boolean;
    home_phone: string;
    office_phone: string;
    mobile_phone: string;
    fax: string;
    case_mgmt: boolean;
    pricing: boolean;
    commitment: boolean;
    closing: boolean;
    deleted: boolean;
    entity_note: string;
    broker_status_id: number;
    officer_status: string;
    company_person_type_id: number;
    company_person_type: string;
    company_type_id: number;
    company_type_desc: string;
}

export interface CaseRollback {
    cases_id: number;
    completed_by_user: string;
    completed_by: number;
    reason_id: number;
    requested_by_user: string;
    requested_by: number;
    result_phase: string;
    rollback_date: Date | string;
    rollback_id: number;
    rollback_reason: string;
    last_phase_master_id?: number;
    last_event_master_id?: number;
}

export interface Country {
    country_id: number;
    country_name: string;
    country_abbr: string;
}

export interface State {
    state_id: number;
    state_name: string;
    state_abb: string;
    country_id: number;
    region_id: number;
    approved: boolean;
    pending: boolean;
    prohibited: boolean;
    min_discount_req: boolean;
    min_discount: string;
    comments: string;
    license_type_id: number;
    license_req: boolean;
    license_req_text: string;
    compensation_req: boolean;
    compensation_language: string;
    audit_finance_req: boolean;
    regulated: boolean;
    five_yr: boolean;
    generic_cp: boolean;
    cp_seller_viator: string;
    expiration_date?: string | Date;
    continuous?: boolean;
    license_pending?: boolean;
    region_name?: string;
}

export enum StateID {
    Minnesota = 24
}

export interface Note {
    action_required: boolean;
    assigned_user_id: number | null;
    assigned_user_read_date: Date;
    assigned_user_role_level: number;
    assigned_user_status: boolean;
    assigned_group_id: Role | null;
    assigned_to: string;
    buyer_name: string;
    cases_id: number;
    /** Closing Package */
    cp: boolean;
    created_by: number;
    created_by_name: string;
    created_date: Date;
    /** Commitment to Purchase */
    ctp: boolean;
    followup_date: Date | string;
    followup_text: string;
    followup_complete: boolean;
    followup_complete_by: number;
    followup_complete_by_name: string;
    followup_complete_date: Date;
    insured_last_name: string;
    last_updated_by: number;
    last_updated_date: Date;
    last_updated_by_name: string;
    legal_log_id: number;
    legal_log_details: LegalLogDetail[] | string;
    note_id: number;
    note_phase: string;
    note_status_id: number;
    note_status: string;
    note_text: string;
    note_type_id: number;
    OverdueNoteCount?: number;
}

export interface GetNotesParams {
    cases_id: number | null;
    assigned_user_id: number | null;
    isNoteCounter: boolean;
}

export interface RegionRep {
    region_id: number;
    region_name: string;
}

export interface Disease {
    disease_id?: number;
    disease_name: string;
    icd_diagnosis_code: number | null;
    alt_code_id: number | null;
    description?: string | null;
}

export interface DiseaseAltCode {
    alt_code_id?: number;
    description: string;
}

export interface EscrowAgent {
    escrow_agent_id: number;
    company_id: number;
    company_name: string;
}

export interface File {
    cases_id: number;
    created_by: number;
    created_date: Date;
    file_key: string;
    file_name: string;
    file_type_id: number;
    legal_log_id: number;
    u_name: string;
}

export interface FileEntry {
    file_key: string;
    file_name: string;
}

export enum FileType {
    "Case Management" = 1,
    "Closing" = 2,
    "Legal" = 3,
    "Pricing" = 4,
    "Servicing" = 5
}

export interface OverdueNoteCount {
    noteCount: number | undefined;
    lastFetch: Date | undefined;
}
